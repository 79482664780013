body > header
	z-index: 1
	position: fixed
	left: 0
	right: 0
	background: var(--color-white)
	box-shadow: 0 0 0 rgba(0,0,0,.25)
	transition: box-shadow 0.3s ease
	&::after
		content: ''
		clear: both
		display: block

	> a
		display: block
		padding-left: 20px
		margin: var(--grid) 0
		float: left
		width: calc(100% - 40px)
		transition: margin 0.3s ease
		img
			width: 250px
			height: auto
			transition: all 0.3s ease

	&.active
		box-shadow: 0 2px 15px rgba(0,0,0,.25)
		transition: box-shadow 0.3s ease
		nav:first-of-type
			opacity: 0
			height: 0
			transition: opacity 0.3s ease, height 0.3s ease
		> a
			margin: 5px 0
			transition: margin 0.3s ease
			img
				width: 180px
				height: auto
				transition: all 0.3s ease
		.offcanvas-icon
			top: 5px

		nav:nth-of-type(2)
			top: 57px

	.offcanvas-icon
		z-index: 1
		width: 40px
		cursor: pointer
		height: 40px
		right: 10px
		top: calc(50% - 8px)
		position: absolute
		span
			position: absolute
			height: 3px
			cursor: pointer
			left: 5px
			width: 30px
			background: var(--color-black)
			border-radius: 10px
			top: 10px
			transition: top 0.3s ease-in, transform 0.3s ease-in, opacity 0.3s ease-in
			&:nth-child(2)
				top: 20px
			&:nth-child(3)
				top: 30px

		&.active
			span
				transition: top 0.3s ease-out, transform 0.3s ease-out, opacity 0.3s ease-out
				top: 20px
				&:nth-child(1)
					transform: rotate(45deg)
				&:nth-child(2)
					opacity: 0
				&:nth-child(3)
					transform: rotate(-45deg)

	nav:first-of-type
		transition: opacity 0.3s ease, height 0.3s ease
		overflow: hidden
		height: 36px
		background: var(--color-gray)
		text-align: right
		*
			font-size: 12px

	nav
		li
			display: inline-block
		a
			display: inline-block
			line-height: 36px
			padding: 0 10px
			height: 36px
			&:hover,
			&.active
				color: var(--color-gold)

	nav:nth-of-type(2)
		overflow-y: scroll
		z-index: 0
		background: rgba(255,255,255,0.85)
		border-left: solid 1px var(--color-gray)
		backdrop-filter: blur(4px)
		position: fixed
		width: 320px
		right: -320px
		box-sizing: border-box
		padding: var(--grid)
		transition: right 0.35s cubic-bezier(1,.38,.95,.96)
		top: 129px
		bottom: 0

		li
			display: block
			ul li
				padding-left: var(--grid)

		a
			line-height: 40px
			padding: 0 10px
			margin: 5px 0
			height: 40px

		&.active
			transition: right 0.35s cubic-bezier(.27,1.15,.95,.96)
			right: 0

	@media screen and (min-width: 768px)
		position: relative

		&.active
			box-shadow: none
			transition: none

			nav:first-of-type
				opacity: 1
				height: auto
				transition: none
			> a
				margin: var(--grid) auto
				transition: none
				img
					width: 250px
					transition: none

		> a
			margin: var(--grid) auto
			float: none
			padding: 0
			width: 250px

		.offcanvas-icon
			display: none

		nav:nth-of-type(2)
			overflow-y: unset
			text-align: center
			position: static
			width: auto
			border: none
			backdrop-filter: none
			padding: 0

			li
				display: inline-block
				position: relative
				padding-bottom: 50px
				box-sizing: border-box
				&.current-menu-item
					border-bottom: solid 4px var(--color-gold)

				ul
					padding: 10px 20px
					z-index: 1
					text-align: left
					transition: opacity 0.25s ease, top 0.25s ease, 0s 0.25s visibility ease
					opacity: 0
					position: absolute
					visibility: hidden
					background: rgba(255,255,255,0.85)
					backdrop-filter: blur(4px)
					border: solid 1px var(--color-gray)
					top: 56px
					left: 0
					li
						display: block
						white-space: nowrap
						padding: 0
						margin-bottom: 10px
						a
							padding: 0

				&:hover
					ul
						transition: opacity 0.25s ease, top 0.25s ease, 0s 0s visibility ease
						visibility: visible
						opacity: 1
						top: 46px
			a
				margin: 0
				display: inline-block
				line-height: 36px
				padding: 0 10px
				height: 36px
