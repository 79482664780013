.details
	article
		background: var(--color-white)
		padding: var(--grid) 10px
		margin-top: var(--grid)

	.column
		margin-bottom: 20px

	.btn
		float: right
		height: 37px

	strong
		font-family: 'italic'
		display: block

	hr
		border: solid 2px var(--color-gray)
		margin: var(--grid) 0

	@media screen and (min-width: 768px)
		article
			padding: var(--grid)
			padding-bottom: calc(var(--grid) + 37px)

		.content
			display: flex
			gap: 20px
			margin-bottom: var(--grid)

		.column
			width: 50%
			margin: 0

	@media screen and (min-width: 1024px)
		content
			gap: 40px
