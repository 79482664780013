.article-double
	background: var(--color-white)
	padding: var(--grid) 10px
	box-sizing: border-box

	&.training-details
		margin-top: var(--grid)

	p
		margin-bottom: 15px

	li
		list-style: disc

	strong
		color: var(--color-true-black)

	em
		font-family: 'italic'

	@media screen and (min-width: 768px)
		padding: var(--grid)

		.content
			column-count: 2
			column-gap: 40px

	@media screen and (min-width: 1440px)
		padding: calc(var(--grid) * 2)
