footer.tagbar
	margin-top: 20px
	padding: 20px 10px
	border: none
	background: var(--color-green)
	color: var(--color-white)
	text-align: center

	@media screen and (min-width: 768px)
		margin-top: 40px 20px
