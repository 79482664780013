.blog
	.card
		background: var(--color-white)
		margin-bottom: 10px
		img
			width: 100%
			height: auto
	.content
		padding: 10px

	@media screen and (min-width: 768px)
		.grid
			flex-wrap: wrap
			display: flex
			flex-direction: row
			gap: 15px

		.card
			margin-bottom: 15px
			width: calc(33.3% - 10px)
