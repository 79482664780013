.glossar-categories
	background: var(--color-green)
	padding: 20px 0

	.container
		padding: 0 var(--grid)
	a
		color: var(--color-white)
		display: block
		padding: 10px 0
		margin-bottom: 5px
		&:hover
			text-decoration: underline

	@media screen and (min-width: 560px)
		a
			display: inline-block
			padding: 0
			margin: 0 20px 15px 0
