.single

	.posts
			text-align: center

			.btn
				margin-top: 20px
				display: inline-block
				height: auto

	article
		padding: var(--grid)
		max-width: 800px
		h1
			padding: var(--grid)
			background: var(--color-green-light)
			color: var(--color-white)
			&::after
				display: none

		img
			width: 100%
			height: auto

		.content
			padding: var(--grid)
			background: var(--color-white)

		.btn.back
			display: inline-block
			margin: 15px auto

	@media screen and (min-width: 768px)

		article

			.btn.back
				margin: 30px auto
