.archive-product

	header
		background: var(--color-green)
		color: var(--color-white)
		padding: 20px 10px

		h1
			color: var(--color-white)
			&::after
				display: none

	main
		.products
			padding-left: 10px
			padding-right: 10px
			display: flex
			flex-wrap: wrap
			gap: 10px

			.product
				display: block
				overflow: hidden
				width: calc(50% - 5px)
				background: var(--color-white)
				margin-bottom: 10px
				border-radius: 7px
				box-shadow: 0 4px 12px rgba(0,0,0,0.1)
				box-sizing: border-box
				position: relative
				transition: transform ease 300ms, box-shadow ease 300ms

				&:hover
					box-shadow: 0 0 15px rgba(0,0,0,0.2)
					transform: translateY(-4px)
					transition: transform ease 300ms, box-shadow ease 300ms

			.woocommerce-loop-product__link:nth-child
				display: block

			.add_to_cart_button
				content: 'Zum Webinar'
				background: var(--color-gold)
				color: var(--color-white)
				padding: 4px 15px 5px 15px
				box-sizing: border-box
				height: 32px
				white-space: nowrap
				border-radius: 120px
				border: solid 2px var(--color-gold)
				transition: .25s
				display: block
				box-sizing: border-box
				margin: 0 5px 5px 5px

				&:hover
					background: var(--color-gold-hover)
					transition: .25s

			img
				width: 100%
				height: auto
				margin-bottom: 10px

			h2
				padding: 10px
				font-size: 18px
				font-family: 'light'
				&::after
					display: none

			.price
				padding-left: 10px
				display: block
				//float: left
				width: 60px
				font-family: 'light'
				margin-bottom: 20px

	@media screen and (min-width: 768px)
		main
			.products
				gap: 20px

				.product
					width: calc(33% - 13px)
					margin-bottom: 20px

	@media screen and (min-width: 1024px)
		main
			.products
				gap: 20px

				.product
					width: calc(25% - 15px)

			h2
				font-size: 24px

			.price
				float: left

			.add_to_cart_button
				display: inline
				margin: 0
				position: absolute
				bottom: 10px
				right: 10px



	@media screen and (min-width: 1440px)
		header
			padding: 20px 0
