.single-product
	.woocommerce-product-gallery
		img
			width: calc(100% - 20px)
			margin: 0 10px 20px 10px
			height: auto

	.summary
		padding: 0 10px
		margin-bottom: 20px

	h1
		&::after
			display: none

	.quantity,
	#ppcp-messages
		display: none !important

	.price
		display: block
		margin-top: 10px
		margin-bottom: 20px
		font-size: 26px
		text-align: right

		span,
		bdi
			font-size: 26px

	.product_meta,
	.woocommerce-tabs,
	.related
		display: none

	.woocommerce-product-details__short-description
		p
			margin-bottom: 20px

	.single_add_to_cart_button
		display: block
		width: 100%
		text-align: center
		margin-bottom: 20px
		content: 'Zum Webinar'
		background: var(--color-gold)
		color: var(--color-white)
		padding: 4px 15px 5px 15px
		box-sizing: border-box
		height: 55px
		cursor: pointer
		white-space: nowrap
		border-radius: 120px
		border: solid 2px var(--color-gold)
		transition: .25s

		&:hover
			background: var(--color-gold-hover)
			transition: .25s

	.woocommerce-notices-wrapper
		margin: 0 10px

	.tabs
		display: none

	@media screen and (min-width: 768px)
		.woocommerce-product-gallery
			float: left
			width: 50%

			img
				width: 100%
				margin: 0 0 20px 0



		.product
			&::after
				display: block
				clear: both
				content: ''

		.single-product
			.summary,
			.woocommerce-tabs
				float: right
				box-sizing: border-box
				width: 50%
				padding: 20px

	@media screen and (min-width: 1440px)

		.woocommerce-notices-wrapper
			margin: 0
