.woocommerce-cart
	.wc-block-cart-item__remove-link
		text-decoration: none !important
		color: red !important

	.wc-block-components-product-name
		font-family: 'light'
		margin-bottom: 10px
		display: block

	.wc-block-cart__submit-button
		display: block
		width: 100%
		line-height: 48px
		text-align: center
		margin-bottom: 20px
		content: 'Zum Webinar'
		background: var(--color-gold)
		color: var(--color-white)
		padding: 4px 15px 5px 15px
		box-sizing: border-box
		height: 55px
		cursor: pointer
		white-space: nowrap
		border-radius: 120px
		border: solid 2px var(--color-gold)
		transition: .25s

		span
			color: var(--color-white)

		&:hover
			background: var(--color-gold-hover)
			transition: .25s
	h1
		&::after
			display: none

	main
		padding-left: 10px
		padding-right: 10px

	@media screen and (min-width: 1440px)

		main
			padding-left: 0
			padding-right: 0
