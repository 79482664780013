.contact
	background: var(--color-white)
	padding: var(--grid) 10px
	box-sizing: border-box

	h3
		margin-bottom: var(--grid)
		&::after
			display: none

	@media screen and (min-width: 768px)
		padding: var(--grid)
