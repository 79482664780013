.woocommerce-order-received

	h1
		display: none

	.woocommerce-order
		border-radius: 12px
		background: var(--color-white)
		margin: 10px
		padding: 10px
		box-sizing: border-box

		.woocommerce-notice
			margin-bottom: 20px

			&.woocommerce-thankyou-order-received
				color: var(--color-green)


		ul
			li
				display: block
				margin-bottom: 5px

				strong
					float: right

		a
			color: var(--color-gold)
			text-decoration: underline

			&:hover
				text-decoration: none

		table
			background: var(--color-gold)
			width: 100%

			th, td
				padding: 5px
				background: var(--color-white)
				text-align: left
				font-size: 16px

				a
					font-size: 16px

		.includes_tax
			display: none

		.order-again
			display: block
			margin-top: 20px

		.woocommerce-order-details
			margin: 20px 0

		.wpwebinarsystem-join-webinar-wc-notice
			border-radius: 8px
			border: none
			background: var(--color-gold)
			p
				color: var(--color-white)

	@media screen and (min-width: 1024px)
		.woocommerce-order
			max-width: 800px
			margin: 20px auto
			padding: 20px
