.contactpage
	background: var(--color-white)
	padding: var(--grid) 10px

	aside
		a
			color: var(--color-white)
			background: var(--color-gold)
			padding: 5px 20px 5px 10px
			display: inline-flex
			align-items: center
			border-radius: 2px
			margin-bottom: 10px


			img
				float: left
				margin-right: 10px
				width: 16px
				height: 16px

	@media screen and (min-width: 768px)
		background: transparent
		padding: 0
		display: flex
		gap: 40px
		aside, .contactform
			background: var(--color-white)
			padding: var(--grid)
			width: 50%
