.course-carousel
	margin-top: var(--grid)

	article
		background: var(--color-white)
		padding: 25px var(--grid)

		p
			margin-bottom: 30px

	@media screen and (min-width: 768px)
		display: flex
		gap: 40px

		article
			padding: var(--grid)
