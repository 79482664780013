.hero
	position: relative
	img
		display: block
		width: 100%
		height: auto

	article
		max-width: 600px
		box-sizing: border-box
		background: rgba(#698143, 0.7)
		backdrop-filter: blur(10px)
		padding: 10px
		h1::after
			display: none
		h1, p
			margin-bottom: 10px
			color: var(--color-white)

	@media screen and (min-width: 768px)
		article
			padding: 20px 40px
			position: absolute
			right: -40px
			bottom: 40px
