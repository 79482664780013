#wpdmlogin
	background: var(--color-white)
	padding: 10px
	max-width: 600px
	margin: auto
	&::after
		content: ''
		clear: both
		display: block

	@media screen and (min-width: 768px)
		padding: 20px

	@media screen and (min-width: 1440px)
		padding: 40px


	.wpdm-checkbox
		margin-right: 15px
		position: relative
		top: 5px

	.row
		margin-bottom: 20px

	.btn-primary
		background: var(--color-gold)
		color: var(--color-white)
		transition: 0.25s
		cursor: pointer
		float: right
		line-height: 19px
		&:hover
			background: var(--color-gold-hover)
			transition: 0.25s



#wpdm-all-packages
	input[type="search"]
		background: var(--color-white)


	.btn-primary,
	.btn
		text-align: center
		line-height: 19px
		transition: 0.25s

		&:hover
			background: var(--color-gold)
			color: var(--color-white)
			transition: 0.25s

	.btn-danger
		overflow: hidden
		border: none

		&::before
			content: 'Kein Zugriff'
			display: inline-block


	@media screen and (min-width: 768px)
		input[type="search"]
			min-width: 320px

	@media screen and (min-width: 1440px)
		input[type="search"]
			min-width: 500px
