.partner
	padding: 20px
	display: flex
	box-sizing: border-box
	align-items: center
	flex-wrap: wrap
	justify-content: space-between

	a
		display: block
		max-width: 40%

		img
			width: 100%
			height: auto

	@media screen and (min-width: 768px)
		padding: 40px 0

		a
			max-width: 15%
