.woocommerce-checkout
	label
		font-size: 12px !important

	.ppcp-place-order-description
		text-align: left !important
		font-size: 14px

	#payment-method
		.wc-block-components-radio-control
			border: none
			background: var(--color-green)
			border-radius: 8px
			color: var(color-white)
			*
				color: var(--color-white)

	main
		padding-left: 10px
		padding-right: 10px
	h1, h2
		&::after
			display: none

	.wc-block-checkout__payment-method .wc-block-components-radio-control-accordion-option:last-child:after, .wc-block-checkout__payment-method .wc-block-components-radio-control__option:last-child:after
		border: none !important

	.wc-block-components-product-metadata__description
		display: none

	.wc-block-components-panel__button[aria-expanded=true]
		margin-bottom: 20px

	.wc-block-checkout__actions.wp-block-woocommerce-checkout-actions-block
		.wc-block-checkout__actions_row > button
			background: #ffc439
			color: #253B80
			font-weight: bold
			border-radius: 100px
			cursor: pointer
			margin-bottom: 40px

			&:hover
				filter: brightness(0.95)
	.wc-block-checkout__add-note
		span
			font-size: 14px

	.wc-block-checkout__terms
		font-size: 14px
		*
			font-size: 14px
		a
			color: var(--color-green)

	.wc-block-components-checkbox .wc-block-components-checkbox__input[type=checkbox]
		height: 30px
