$MQTablet: 767px
$MQDesktop: 1024px

:root
	--color-green: #698143
	--color-green-light:#768C53
	--color-gold:#B9A063
	--color-gold-hover:#8d7947
	--color-gray:#F0F0F0
	--color-gray-dark: #949699
	--color-white: #fff
	--color-black: #383C42
	--color-true-black: #000
	--grid: 15px

	@media screen and (min-width: 1024px)
		--grid: 25px

	@media screen and (min-width: 767px)
		--grid: 45px

.hgKElc
	display: none

@font-face
	font-family: 'italic'
	src: url('assets/font/nunito-italic-variablefont_wght-webfont.woff2') format('woff2'), url('assets/font/nunito-italic-variablefont_wght-webfont.woff') format('woff')
	font-weight: normal
	font-style: normal

@font-face
	font-family: 'light'
	src: url('assets/font/nunito-variablefont_wght-webfont.woff2') format('woff2'), url('assets/font/nunito-variablefont_wght-webfont.woff') format('woff')
	font-weight: normal
	font-style: normal

*
	margin: 0
	padding: 0
	border: none
	font-family: 'light'
	outline: none
	text-decoration: none
	color: var(--color-black)
	font-size: 18px
	font-style: normal

ul, ol
	li
		list-style: none

html, body
	overflow-x: hidden

main
	padding-top: 130px
	background: var(--color-gray)

	@media screen and (min-width: 768px)
		padding: var(--grid) 0

h1, h2, h3
	font-size: 24px
	line-height: 32px
	&::after
		content:''
		display: block
		width: 80px
		margin-top: 10px
		height: 3px
		background: var(--color-green)
		margin-bottom: var(--grid)

	&.centered
		text-align: center
		&::after
			display: none

h4, h5, h6
	font-size: 20px
	line-height: 28px

p
	line-height: 1.3em

.container
	width: 100%
	max-width: 1360px
	margin: auto

.woocommerce-notices-wrapper > ul
	background: red
	color: white
	padding: 15px
	border-radius: 15px
	margin-bottom: 40px
	li, a
		color: white
	a
		text-decoration: underline

.btn
	padding: 4px 15px 5px 15px
	box-sizing: border-box
	height: 32px
	white-space: nowrap
	border-radius: 120px
	border: solid 2px var(--color-gold)
	transition: 0.25s

	&.text
		float: right
		padding: 5px 0
		border-color: transparent
		color: var(--color-white)

	&.primary
		background: var(--color-gold)
		color: var(--color-white)
		&:hover
			background: var(--color-gold-hover)
			transition: 0.25s

	&.secondary
		background: transparent
		color: var(--color-gold)
