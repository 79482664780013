input
	height: 40px
	background: var(--color-gray)
	line-height: 40px
	box-sizing: border-box
	border: solid 2px transparent
	transition: border 0.3s ease
	margin-bottom: 15px
	padding: 0 15px
	&:not([type='submit'])
		width: 100%

	&:focus
		border-color: var(--color-gold)
		transition: border 0.3s ease
textarea
	background: var(--color-gray)
	padding: 15px
	width: 100%
	box-sizing: border-box

label
	margin-bottom: 10px
	font-size: 12px

input[type="checkbox"]
	width: 20px
	height: 20px
	display: inline-block

input[type="submit"]
	float: right
	background: var(--color-gold)
	color: var(--color-white)
	padding: 4px 15px 5px 15px
	box-sizing: border-box
	height: 32px
	line-height: 20px
	border-radius: 120px
	border: solid 2px var(--color-gold)

::placeholder
	color: var(--color-gray-dark)

.legal-text
	color: var(color-green)
	font-size: 12px

.wpcf7-not-valid
	border-color: red

.wpcf7-not-valid-tip
	display: none

.screen-reader-response
	display: none

.wpcf7-response-output
	border: none
	margin: 0
	padding: 0
	font-size: 12px
	color: #ffb900
