.archive-glossar
	padding-left: 10px
	padding-right: 10px

	header
		margin-bottom: 20px

	.blog
		.card
			position: relative

			> a
				position: absolute
				left: 0
				top: 0
				right: 0
				bottom: 0
				transition: box-shadow ease 300ms

				&:hover
					+ .content
						transition: box-shadow ease 300ms
						box-shadow: 0 2px 12px rgba(0,0,0,0.2)

	@media screen and (min-width: 768px)

		header
			margin-bottom: 40px
