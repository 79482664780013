.fifty-fifty
	display: flex
	flex-direction: column-reverse

	figure
		position: relative
		img
			width: 100%
			height: auto

	figcaption
		position: absolute
		left: 10px
		bottom: 10px
		color: var(--color-white)
		font-size: 12px
		mix-blend-mode: difference

	.content
		background: var(--color-white)
		padding: 30px 10px 50px 10px
	p
		margin-bottom: var(--grid)

	@media screen and (min-width: 768px)
		align-items: center
		flex-direction: row
		gap: 40px
		margin: var(--grid) auto

		&.align-left
			flex-direction: row-reverse

		.content
			width: 50%
			padding: 80px

		figure
			width: 50%
