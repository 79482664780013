.subheader
	position: relative
	img
		width: 100%
		height: auto

	header
		background: var(--color-green-light)
		padding: var(--grid) 10px
		h1::after
			display: none
		*
			color: var(--color-white)

	.content
		padding: 25px 10px

		h2
			margin-bottom: 15px
		p
			margin-bottom: 30px

		.btn
			float: right
			height: 37px

		&::after
			content: ''
			display: block
			clear: both

	.align-right
		background: var(--color-white)
		bottom: 0


	@media screen and (min-width: 768px)
		display: flex
		img, .align-right
			width: 50%

		img
			align-self: center

		.content, header
			padding: var(--grid)

		.align-right
			position: static
