.newsletter
	position: relative
	background: var(--color-white)
	padding: 40px 10px 80px 10px
	box-sizing: border-box
	margin: var(--grid) auto

	fieldset:first-of-type
		p:nth-of-type(2)
			display: flex
			justify-content: space-between
			align-items: center
			span input
				width: 100%

	h3::after
		display: none

	h4
		margin-bottom: var(--grid)

	li
		position: relative
		padding-left: 39px
		margin-bottom: 5px

		.icon
			position: absolute
			left: 0
			width: 24px
			height: 24px
			display: block

	input[type="checkbox"]
		position: absolute
		left: 0
		top: 0

	.legal
		position: relative
		padding-left: 30px
		font-size: 12px
		color: var(--color-green)
		a, label
			display: inline-block
			font-size: 12px
			line-height: 1.3em
			color: var(--color-green)
		a
			text-decoration: underline

	.wpcf7
		padding-top: var(--grid)

	.bg-image
		position: absolute
		height: auto

		&.left
			width: 62px
			left: 5px
			top: -10px

		&.right
			width: 94px
			right: 20px
			bottom: 20px


	@media screen and (min-width: 768px)
		padding: var(--grid)
		display: flex
		gap: 20px

		.bg-image
			&.left
				left: -10px
				top: -10px

		article
			width: 50%

		.wpcf7
			max-width: 50%

	@media screen and (min-width: 768px)
		gap: 40px
		li
			margin-bottom: 10px
