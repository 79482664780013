#wpdm-all-packages .btn-primary,
#wpdm-all-packages .btn,
.w3eden .btn-primary
	background: var(--color-gold)
	color: var(--color-white)
	transition: background 250ms ease

#wpdm-all-packages .btn-primary:hover,
#wpdm-all-packages .btn:hover,
.w3eden .btn-primary:hover
	background: var(--color-green) !important
	transition: background 250ms ease !important
