footer
	background-color: var(--color-white)
	border-bottom: solid 20px var(--color-green)
	box-sizing: border-box
	&::after
		content: ''
		display: block
		clear: both

	.footer-info
		&.vat-info
			text-align: left
			padding: 10px

	.container > a
		display: inline-block
		width: 250px
		margin: var(--grid)
		img
			width: 250px
			height: auto

	address
		padding: 0 var(--grid)
		margin-bottom: var(--grid)
	nav
		li
			padding: 0 var(--grid)
			display: block
			height: 40px
			margin: 5px 0
			border-top: solid 4px var(--color-gray)
			line-height: 40px

			a
				height: 40px
				line-height: 40px
				&:hover,
				&.active
					color: var(--color-gold)

	nav:nth-of-type(1)
		background: var(--color-gray)

	@media screen and (min-width: 768px)
		padding: 20px 20px 0 20px
		.container > a
			margin: 0 0 var(--grid) 0

		address
			padding: 0

		nav
			li
				padding: 0
				height: auto
				margin: 0
				border: none
				display: inline-block
				margin-left: 20px
				line-height: 36px
				a
					height: auto
					line-height: 36px
			ul, ol
				float: right

		nav:nth-of-type(1)
			background: transparent

		nav:nth-of-type(2)
			border: none

	@media screen and (min-width: 1024px)
		padding-top: 40px

	@media screen and (min-width: 1400px)
		padding: 40px 0 0 0
