.usps
	margin: var(--grid) auto
	display: flex
	flex-wrap: wrap
	justify-content: space-between
	padding: 10px

	figure
		width: 100%
		padding: var(--grid) 0

		img
			float: left
			width: 30px
			height: 30px

		figcaption
			margin-left: 15px
			display: inline-block
			line-height: 30px

	@media screen and (min-width: 768px)
		gap: 40px
		figure
			padding: 0
			width: calc(50% - 20px)

	@media screen and (min-width: 1024px)
		gap: 0
		margin: 80px auto
		flex-wrap: nowrap
		figure
			width: auto
